











































































































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import newsStore from "@/store/modules/newsFeed";
import { NewsCategory } from "@/store/models/newsFeed";
import helpers from "@/utils/helpers";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import { BSToAD } from "bikram-sambat-js";

@Component({
  components: {
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  checkAll: boolean = false;
  isIndeterminate: boolean = false;

  isCreate: boolean = true;
  documents: File[] = [];
  notificationTo: string[] = [];

  newsFeedCategoryList: NewsCategory[] = [];
  newsFeedTypeList: string[] = [];

  get AdminRouter() {
    return AdminRouter;
  }
  get newsFeed() {
    return newsStore.thenews;
  }

  handleCheckAllChange(val: any) {
    this.notificationTo = val ? this.newsFeedTypeList : [];
    this.isIndeterminate = false;
  }

  handleCheckedTypeChange(value: any) {
    let checkedCount = value.length;
    this.checkAll = checkedCount === this.newsFeedTypeList.length;
    this.isIndeterminate =
      checkedCount > 0 && checkedCount < this.newsFeedTypeList.length;
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    const fileDocsExt = file.raw.name.split(".").length - 1;
    if (
      file.raw.name.split(".")[fileDocsExt] === "pdf" ||
      file.raw.name.split(".")[fileDocsExt] === "docx"
    ) {
      vm.documents = [file.raw];
      vm.newsFeed.documents = file.raw;
    } else {
      this.$snotify.error("Please upload .docx or .pdf file");
      file = "";
      vm.documents = [];
      vm.newsFeed.documents = null;
    }
  }

  handleRemove(file: any, fileList: any) {
    let vm = this;
    vm.documents = [];
    vm.newsFeed.documents = null;
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.newsFeed.publishedDate = bsAD;
      this.newsFeed.publishedDateBs = e;
    }
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.newsFeed.id) {
      helpers.removeFilesForPatch([
        {
          property: this.newsFeed,
          fieldName: "documents",
        },
      ]);
    }

    this.newsFeed.userTypeToSendPushNotification = this.notificationTo.map(
      (userType) => userType.toLowerCase()
    );
    await newsStore.createNewsFeed();
    this.$snotify.success("News  Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.NewsFeed);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.NewsFeed);
  }

  async created() {
    let vm = this;
    const id = vm.$route.query["id"];
    if (id) {
      vm.isCreate = false;
      await newsStore.loadNewsFeedById(id);
      let b = newsStore.thenews;
      let a = this.newsFeed;

      if (b && b.userTypeToSendPushNotification) {
        this.notificationTo = b.userTypeToSendPushNotification;
      }
    } else {
      newsStore.resetField();
      await newsStore.getAllNewsFeedCategory();
      await newsStore.getAllNewsFeedTypes();
    }
    this.newsFeedCategoryList = newsStore.newsCategoryList;
    this.newsFeedTypeList = newsStore.newsTypeList.map((e) => e.name);
  }

  mounted() {}
  updated() {}
}
