var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("News")])]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.NewsFeed}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('Title'),
                'is-required': _vm.isFieldRequired('Title'),
              },attrs:{"label":"Title"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"News Title","name":"Title"},model:{value:(_vm.newsFeed.title),callback:function ($$v) {_vm.$set(_vm.newsFeed, "title", $$v)},expression:"newsFeed.title"}}),(_vm.errors.has('Title'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Title")))]):_vm._e()],1),_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('Description'),
                'is-required': _vm.isFieldRequired('Description'),
              },attrs:{"label":"Description"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Description","name":"Description"},model:{value:(_vm.newsFeed.description),callback:function ($$v) {_vm.$set(_vm.newsFeed, "description", $$v)},expression:"newsFeed.description"}}),(_vm.errors.has('Description'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Description")))]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"Push Notification"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-checkbox',{staticStyle:{"margin-right":"30px"},attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("All")]),_c('el-checkbox-group',{on:{"change":_vm.handleCheckedTypeChange},model:{value:(_vm.notificationTo),callback:function ($$v) {_vm.notificationTo=$$v},expression:"notificationTo"}},_vm._l((_vm.newsFeedTypeList),function(type){return _c('el-checkbox',{key:type,attrs:{"label":type}})}),1)],1)]),_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('News Category'),
                'is-required': _vm.isFieldRequired('News Category'),
              },attrs:{"label":"News Category"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Category","name":"News Category"},model:{value:(_vm.newsFeed.newsCategory),callback:function ($$v) {_vm.$set(_vm.newsFeed, "newsCategory", $$v)},expression:"newsFeed.newsCategory"}},_vm._l((_vm.newsFeedCategoryList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(_vm.errors.has('News Category'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("News Category")))]):_vm._e()],1),_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('Publish Date'),
                'is-required': _vm.isFieldRequired('Publish Date'),
              },attrs:{"label":"Publish Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Date","name":"Publish Date"},on:{"change":_vm.bsDateChange},model:{value:(_vm.newsFeed.publishedDateBs),callback:function ($$v) {_vm.$set(_vm.newsFeed, "publishedDateBs", $$v)},expression:"newsFeed.publishedDateBs"}}),(_vm.errors.has('Publish Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Publish Date")))]):_vm._e()],1),_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('Content'),
                'is-required': _vm.isFieldRequired('Content'),
              },attrs:{"label":"Content"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"textarea","placeholder":"Content here ...","name":"Content"},model:{value:(_vm.newsFeed.content),callback:function ($$v) {_vm.$set(_vm.newsFeed, "content", $$v)},expression:"newsFeed.content"}}),(_vm.errors.has('Content'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Content")))]):_vm._e()],1),_c('el-form-item',{class:{
                'is-error': _vm.fieldHasErrors('News Docs'),
                'is-required': _vm.isFieldRequired('News Docs'),
              },attrs:{"label":"Documents"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"name":"News Docs","on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.documents,"accept":"application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}},[_c('el-button',{attrs:{"size":"small","type":"primary","round":""},model:{value:(_vm.documents),callback:function ($$v) {_vm.documents=$$v},expression:"documents"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(_vm.newsFeed.documentsName)?_c('div',[_c('a',{attrs:{"href":_vm.newsFeed.documents,"target":"_blank"}},[_vm._v(_vm._s(_vm.newsFeed.documentsName))])]):_vm._e(),(!_vm.newsFeed.documents && !_vm.errors.has('News Docs'))?_c('small',{staticClass:"error",staticStyle:{"margin-top":"-10px","font-weight":"bold"}},[_vm._v(" Upload files of extensions .docx or .pdf ")]):_vm._e(),(_vm.errors.has('News Docs'))?_c('small',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("News Docs"))+" ")]):_vm._e()],1),_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }